















import { Component, Vue, Prop } from "vue-property-decorator";
import List from "./list.vue";
import Detail from "./detail.vue";
@Component({
  components: {
    List,
    Detail,
  },
})
export default class Name extends Vue {
  @Prop()
  private id: any; // 门诊或住院的id
  @Prop()
  private parent: any; // 门诊或住院
  private type: any = "list";
  private selectData: any = {};
  private goDetail(item: any) {
    this.type = "detail";
    this.selectData = item;
  }
  private goList() {
    this.type = "list";
  }
}
